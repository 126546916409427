import Vue from 'vue'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
import LineChart from './LineChart.js'

import moment from "moment";
import vSelect from 'vue-select';

export default {
    components: {
        vSelect,
        LineChart
    },
    data: function() {
        return {
            base_url: Vue.BASE_URL,
            pageTitle: "Chiffre d'affaire",
            dataRecuperer: [],
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtreDate: {
                dateDebut: moment().format('DD/MM/YYYY'),
                dateFin: moment().format('DD/MM/YYYY')
            },
            historiqueDeRemboursement: [],
            total_credit: 0,
            total_debit: 0,
            statut: "",
            loaded: false,
            datacollection: null,
        }
    },
    methods: {

        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        getData() {
            var that = this;
            let formData = new FormData();
            formData.append('startDate', this.filtreDate.dateDebut);
            formData.append('endDate', this.filtreDate.dateFin);
            formData.append('statut', this.statut);

            // this.datacollection = [];
            axios.post(that.base_url+"/tresoreriesmouvement/getdebitcreditallactivite", formData)
            .then(function (response) {
                that.dataRecuperer = response.data;
                var total_credit = 0;
                var total_debit = 0;
                var labels = [];
                var datasets = [];
                $.each(response.data, function(key,value) {
                    // console.log(value);
                    total_credit += Number(value.total_credit);
                    total_debit += Number(value.total_debit);

                    datasets.push(
                        {
                            label: value.nom,
                            backgroundColor: ['#dc3545', '#28a745'],
                            data: [value.total_debit, value.total_credit]
                        }
                    );
                    
                });

                labels = ['DEBIT', 'CREDIT'];

                that.datacollection = {
                    labels: labels,
                    datasets: datasets
                };
                
                that.total_credit = total_credit;
                that.total_debit = total_debit;
            });

        },

        fillData () {
            this.datacollection = {
              labels: [this.getRandomInt(), this.getRandomInt()],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: 'red',
                  data: [this.getRandomInt(), this.getRandomInt()]
                }, {
                  label: 'Data two',
                  backgroundColor: 'green',
                  data: [this.getRandomInt(), this.getRandomInt()]
                }
              ]
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        }
        
        
    },
    computed: {
        
    },
    watch: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        
    },
    mounted: function() {

        this.getData();
        

    }
}